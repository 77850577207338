import axios from "axios";
export default (ruta = "") => {

  let usuario = JSON.parse(sessionStorage.getItem("usuario"))
  let id_usuario = ""
  if (usuario) {
    id_usuario = usuario.id_usuario
  }

  let rutaApi = ""
  if (ruta == "1") {
  //  rutaApi = "https://convocatorias-api.herokuapp.com/api/"
  } else {

    // rutaApi = "http://localhost/dfp_back/public/api/"
    rutaApi="https://develdfp.cloudsenactpi.net/api/" //ruta cloud local y Producción
    // rutaApi="/api/" //ruta cloud local
    // rutaApi="api/" //ruta Producción
    // rutaApi= "http://localhost/dfp_back/public/api/" //ruta "Andres Muñoz"
  }

  return axios.create({
    baseURL: rutaApi,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Usuario": id_usuario
    },
  });
};
