
import Component from 'vue-class-component';
import Slider from '../components/Slider.vue';
import { Vue } from 'vue-property-decorator';
import FooterPer from '../components/FooterPer.vue';
import NavBarInicio from '../components/NavBarInicio.vue';

@Component({
  components: {
    Slider,
    FooterPer,
    NavBarInicio
  }
})
export default class Home extends Vue {

}
